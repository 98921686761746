<template>
  <div className="banner">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h1 class="text-white ml-2">Find Out More</h1>
        </div>
      </div>
    </div>
  </div>

  <div class="container py-md-5">
    <div class="col col-lg-12 bg-white">
      <div class="row text-left mb-2">
        <div class="col">
          <h2>Why do we need the TrustMark Property Hub?</h2>
          <p>
            The Energy Company Obligation (ECO) is a government energy efficiency scheme in Great
            Britain to help reduce carbon emissions and tackle fuel poverty.
          </p>
        </div>
      </div>
    </div>
    <div class="col col-lg-12 bg-white">
      <div class="row text-left mb-2">
        <div class="col">
          <h2 class="text">What does TrustMark Property Hub mean to me?</h2>
          <p>
            New standards (PAS2035:2019) came into force on 1st January 2020, as such all works on
            your home that fall under this framework must be completed by a
            <a href="https://www.trustmark.org.uk/find-a-tradesman"
              >https://www.trustmark.org.uk/find-a-tradesman</a
            >
            and recorded in line with government requirements. Your tradesperson will submit the job
            in the TrustMark Data Warehouse, and you can see the output within the TrustMark
            Property Hub.
          </p>
          <p>
            Typically work included is anything that will have an impact on the energy efficiency of
            your home, e.g. Insulation, boilers, solar panels, ground source heat pumps, etc.
          </p>
        </div>
      </div>
    </div>
    <div class="col col-lg-12 bg-white">
      <div class="row text-left mb-2">
        <div class="col">
          <h2 class="text">The Future.</h2>
          <p>
            We are in a transition as the new standards and lodgement process rolls out, all changes
            become mandatory in June 2021.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FindOutMore',
};
</script>
